<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <payments-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <template v-if="vendor.length > 0">
                  <div
                    v-if="vendor.length > 1"
                    :class="{ 'hide-edges': hideEdges }"
                  >
                    <h2>Vendor details</h2>
                    <h3>{{ vendor[0].name }}</h3>
                    <!-- <p>
                      <strong>Address:</strong>
                      {{ fullAddress }}
                      <br>
                      <strong>EIN:</strong>
                      {{ vendor[0].ein }}
                    </p> -->
                    <v-client-table
                      ref="allVendors"
                      :columns="tableColumns"
                      :options="tableOptions"
                      :data="tableValues"
                    >
                      <template slot="afterLimit">
                        <download-buttons
                          @clicked="downloadFile"
                        />
                      </template>
                      <template
                        slot="address"
                        slot-scope="props"
                      >
                        {{ `${props.row.address1} ${props.row.address2 || ''}` }}
                      </template>
                    </v-client-table>
                  </div>
                  <div v-else>
                    <h3 class="">
                      {{ vendor[0].name }}
                    </h3>
                    <p>
                      <strong>Address:</strong>
                      {{ fullAddress }}
                      <br>
                      <strong>EIN:</strong>
                      {{ vendor[0].ein }}
                    </p>
                  </div>
                </template>
                <template v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsTabs from "@/components/PaymentsTabs";
import DownloadButtons from "@/components/DownloadButtons";

import { options } from "@/config/tableOptions";
const tableColumns = [
  "name",
  // "ein",
  // "address",
  "address1",
  "address2",
  "city",
  "state",
  "ZIP code",
];

export default {
  components: {
    PaymentsTabs,
    DownloadButtons,
  },
  data() {
    return {
      tableColumns,
      tableOptions: options({
        columnsClasses: {
          city: 'td-nowrap',
          zip: 'td-nowrap',
          state: 'td-nowrap',
          ein: 'td-nowrap',
        },
        headings: {
          address1: "Address 1",
          address2: "Address 2",
        },
        sortable: [ 'name' ],
        resizableColumns: true,
      }),
    };
  },
  computed: {
    vendor() {
      return this.$store.state.vendors.vendor || [];
    },
    tableValues() {
      return this.vendor;
    },
    fullAddress() {
      let address = '';
      if (this.vendor[0].address1) {
        address += `${this.vendor[0].address1}, `;
      }
      if (this.vendor[0].address2) {
        address += `${this.vendor[0].address2}, `;
      }
      if (this.vendor[0].city) {
        address += `${this.vendor[0].city}, `;
      }
      if (this.vendor[0].state) {
        address += `${this.vendor[0].state}, `;
      }
      if (this.vendor[0].zip) {
        address += `${this.vendor[0].zip}, `;
      }
      return String(address).replace(/,\s*$/, "");
    },
  },
  async fetch({ store, params }) {
    let vendor = params.id;
    if (params.id === 'own') {
      vendor = null;
    }

    await store.dispatch('vendors/getVendor', vendor);
    return Promise.resolve();
  },
  methods: {
    downloadFile(type) {
      const fileName = `vendor-details`;

      /**
       * Prepare Object to Download
       */
      let data = this.vendor.map(v => [
        String(v.name),
        String(v.ein),
        String(`${v.address1} ${v.address2 || ''}`),
        String(v.city),
        String(v.state),
        String(v.zip),
      ]);

      data.unshift([
        "Name",
        "EIN",
        "Address",
        "City",
        "State",
        "Zip",
      ]);

      if (type === 'csv') {
        this.$helpers.downloadCSV(data, fileName);
      } else {
        this.$helpers.downloadPDF(
          data,
          fileName,
          `Vendor Details`,
          {
            1: { cellWidth: 'wrap' },
            3: { cellWidth: 'wrap' },
            4: { cellWidth: 'wrap' },
            5: { cellWidth: 'wrap' },
          },
          { cellWidth: 'wrap' },
        );
      }

      data = null;
    },
  },
};
</script>
